import { rem } from 'polished';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { css, styled } from 'styles';
import { ScreenReaderContent } from '../../../Shared/Accessibility';
import { moodIconMap } from '../../../Shared/MoodFace';
import { small } from '../../../utils';
import { DonutChart, SliceData } from '../../../Shared/Charts/DonutChart';
import { Mood } from '../types';
import { Heading, ParagraphSmall, Statistic } from '../../Typography';
import TopFeelings from './TopFeelings';

export interface MoodData extends SliceData {
  mood: Mood;
  topFeelings: string[];
}

type MoodScoresByMoodProps = {
  checkIns: MoodData[];
  initialMood: MoodData;
  totalCheckIns: number;
};

const ChartContainer = styled.div`
  width: ${rem(132)};
  margin: 0 auto;

  @media (min-width: ${rem(440)}) {
    margin-left: 0;
    margin-right: ${rem(56)};
  }
`;

const Container = styled.div`
  ${small(css`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  `)}
`;

const TopFeelingsContainer = styled(TopFeelings)`
  margin-top: ${rem(12)};
`;

const HeadingContainer = styled.div`
  flex-shrink: 0;
  margin-bottom: ${rem(20)};

  ${small(css`
    max-width: ${rem(190)};
    margin-right: ${rem(24)};
  `)}
`;

const SubHeading = styled(ParagraphSmall)`
  margin-top: ${rem(4)};
`;

const Content = styled.div`
  @media (min-width: ${rem(440)}) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
`;

export default ({
  checkIns,
  initialMood,
  totalCheckIns,
}: MoodScoresByMoodProps) => {
  const [selectedMood, setSelectedMood] = useState(initialMood);
  const MoodFaceIcon = moodIconMap[selectedMood.mood];
  const { t: translate } = useTranslation<Namespace<'insights'>>('insights');

  return (
    <Container data-testid="mood-scores-by-mood">
      <HeadingContainer>
        <Heading>{translate('moods.checkins_by_mood.heading')}</Heading>
        <SubHeading aria-hidden="true">
          {translate('moods.checkins_by_mood.subheading')}
        </SubHeading>
      </HeadingContainer>
      <Content aria-hidden="true" data-testid="checkins-by-mood-chart">
        <ChartContainer>
          <DonutChart
            data={checkIns}
            onSliceClick={(_, sliceData) => {
              setSelectedMood(sliceData.datum);
            }}
          >
            <MoodFaceIcon width={45} height={45} />
          </DonutChart>
        </ChartContainer>
        <div>
          {/* @ts-ignore */}
          <Statistic>
            {Math.round((selectedMood.count / totalCheckIns) * 100)}%
          </Statistic>
          <ParagraphSmall>
            {Trans({
              t: translate,
              i18nKey: 'moods.checkins_by_mood.total_check_in',
              values: { count: totalCheckIns },
              components: { bold: <strong /> },
            })}
          </ParagraphSmall>
          {selectedMood.topFeelings.length > 0 && (
            <TopFeelingsContainer feelings={selectedMood.topFeelings} />
          )}
        </div>
      </Content>
      <ScreenReaderContent as="table">
        <caption>
          {translate('moods.checkins_by_mood.screen_reader_content.caption')}
        </caption>
        <tbody>
          <tr>
            <th scope="col">
              {translate(
                'moods.checkins_by_mood.screen_reader_content.mood_table_header',
              )}
            </th>
            <th scope="col">
              {translate(
                'moods.checkins_by_mood.screen_reader_content.count_table_header',
              )}
            </th>
            <th scope="col">
              {translate(
                'moods.checkins_by_mood.screen_reader_content.top_feelings_table_header',
              )}
            </th>
          </tr>
          {checkIns.map((checkin, i) => (
            <tr key={i}>
              <td>{Mood[checkin.mood]}</td>
              <td>{checkin.count}</td>
              <td>
                {checkin.topFeelings.map((feeling, j) => (
                  <React.Fragment key={j}>
                    {(j ? ', ' : '') + feeling}
                  </React.Fragment>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </ScreenReaderContent>
    </Container>
  );
};
