import { css, styled } from 'styles';
import { rem } from 'polished';
import { extraSmall, medium, small } from 'utils';
import {
  BodyText,
  Card,
  HeadingText,
} from '@unmind/design-system-components-web';
import PrimaryButton from 'Shared/PrimaryButton';
import SecondaryButton from 'Shared/SecondaryButton';
import { Phone } from 'icons';
import {
  HERO_BANNER_IMAGE_MAX_WIDTH_DESKTOP,
  HERO_BANNER_IMAGE_MAX_WIDTH_MOBILE,
} from 'Explore/constants';
import { Link } from 'react-router-dom';

export const BannerContainer = styled.div`
  display: flex;
  gap: ${rem(24)};

  flex-direction: column;

  ${medium(css`
    flex-direction: row;
  `)}
`;

export const CardContentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.card};
  border-radius: ${rem(32)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  width: 100%;

  padding: 20px;

  ${small(css`
    flex-direction: column;
  `)}
`;

export const CardActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${extraSmall(css`
    align-items: normal;
  `)}
`;

export const CardWrapper = styled(Card)`
  display: flex;
  max-width: ${rem(742)};
  padding: 0;
  flex-direction: column-reverse;
  border: none;
  border-radius: 12px;

  ${extraSmall(css`
    flex-direction: row;
  `)}
`;

export const Title = styled(HeadingText)`
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize18}px;
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
  margin-bottom: ${rem(16)};
`;

export const Subtitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.regular,
  forwardedAs: 'p',
}))`
  margin-bottom: ${rem(16)};
  color: ${({ theme }) => theme.colors.text.secondary};
  ${small(css`
    line-height: 1.4;
  `)}
`;

export const BrowseButton = styled(PrimaryButton)`
  width: 100%;

  ${extraSmall(css`
    width: fit-content;
  `)}
`;

export const TextWrapper = styled.div`
  text-align: center;

  ${small(css`
    text-align: left;
    margin-right: ${rem(22)};
  `)}
`;

export const CardImageContainer = styled(Link).attrs(() => ({
  ['aria-hidden']: true,
}))`
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: ${HERO_BANNER_IMAGE_MAX_WIDTH_MOBILE};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  ${extraSmall(css`
    border-top-left-radius: 0;
    border-bottom-right-radius: 12px;
    max-width: 65%;
  `)}

  :hover > :nth-child(2) > :nth-child(1) {
    transform: scale(1.1);
    transition: transform 200ms ease-in-out;
  }

  ${medium(css`
    max-width: ${HERO_BANNER_IMAGE_MAX_WIDTH_DESKTOP};
  `)};
`;

export const StyledImage = styled.img`
  object-fit: cover;
  display: block;
  height: 100%;
  width: 103%;
  overflow: hidden;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  ${extraSmall(css`
    border-top-left-radius: 0;
    border-bottom-right-radius: 12px;
    width: 100%;
  `)}
`;

export const SupportContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.background.card};
  border-radius: ${rem(12)};
  padding: ${rem(20)};
  gap: ${rem(16)};
  min-height: ${rem(190)};

  ${medium(css`
    min-height: ${rem(226)};
  `)}
`;

export const SupportTitle = styled(HeadingText)`
  display: flex;
  align-items: center;
  gap: ${rem(8)};
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize18}px;
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.semiBold};
`;

export const SupportDescription = styled(BodyText)`
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize16}px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  color: ${({ theme }) => theme.colors.text.secondary};
  flex-grow: 1;
`;

export const SupportButton = styled(SecondaryButton)`
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: center;

  > span {
    display: flex;
    gap: ${rem(8)};
  }

  ${medium(css`
    margin-top: ${rem(16)};
  `)}

  ${extraSmall(css`
    width: fit-content;
    justify-content: flex-start;
  `)}
`;

export const SupportButtonText = styled(BodyText)`
  font-size: ${({ theme }) => theme.typography.fontSizes.fontSize16}px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 100%;
`;

export const HelpPhoneIcon = styled(Phone).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  height: 16,
  width: 16,
  ['aria-hidden']: true,
}))`
  align-self: center;
`;
