import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { css, styled } from 'styles';
import RoutePath from '../../../App/RoutePath';
import {
  Card,
  CardContentContainer,
  StyledImage,
  Subtitle,
  TextWrapper,
  Title,
} from '../styles';
import ArrowButton from '../../../Shared/ArrowButton';
import { small, medium } from '../../../utils';
import {
  HERO_BANNER_IMAGE_MAX_WIDTH_DESKTOP,
  HERO_BANNER_IMAGE_MAX_WIDTH_MOBILE,
} from '../../constants';
import FallbackWaves from '../../FallbackWaves/FallbackWaves';
import { categoryContent_exploreCategory as CategoryContent } from '../../Category/__generated__/categoryContent';
import { exploreContent_exploreFeaturedHero_ExploreCategory as ExploreCategory } from '../../__generated__/exploreContent';
import { setBreadcrumbState } from '../../BreadcrumbNavigation/setBreadcrumbState';
import { tracking } from '../../../App/Tracking';
import { ContentType } from '../../types';

const CardImageContainer = styled.div`
  border-radius: ${rem(8)};
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: ${HERO_BANNER_IMAGE_MAX_WIDTH_MOBILE};

  ${small(css`
    max-width: 65%;
  `)}

  ${medium(css`
    max-width: ${HERO_BANNER_IMAGE_MAX_WIDTH_DESKTOP};
  `)};
`;

interface HeroBannerProps {
  category: ExploreCategory;
  assetToken?: string | null;
  currentCategory?: CategoryContent;
  source: string;
}

const HeroBannerCategory = ({
  category,
  assetToken,
  source,
  currentCategory,
}: HeroBannerProps) => {
  const { t: translate } = useTranslation('explore');
  const { state } = useLocation();

  const {
    id,
    exploreCategoryTitle,
    exploreCategorySlug,
    summary,
    cloudinaryThumbnail,
    colourTheme,
    internalName,
  } = category;

  const categoryPath = `${RoutePath.Explore}/${exploreCategorySlug}`;

  const newState = setBreadcrumbState({
    currentState: state,
    currentCategory,
    currentTitle: exploreCategoryTitle,
  });

  const trackCategoryClick = () => {
    tracking.track('content-selected', {
      contentId: id,
      contentSlug: exploreCategorySlug,
      contentDuration: null,
      contentType: ContentType.category,
      source,
      contentCategoryPath: internalName,
      isFavourite: null,
    });
  };

  const onNavigateCategory = () => {
    trackCategoryClick();
  };

  return (
    <Card>
      <CardContentContainer data-testid="hero-banner-category">
        <TextWrapper>
          <Title>{exploreCategoryTitle}</Title>
          <Subtitle>{summary}</Subtitle>
        </TextWrapper>
        <ArrowButton
          text={translate(
            'featured_hero_banner.category.cta_button.category.text',
          )}
          to={{
            pathname: categoryPath,
            state: newState,
          }}
          onClick={onNavigateCategory}
          enableMobileWebLink={true}
        />
      </CardContentContainer>
      <CardImageContainer>
        {cloudinaryThumbnail?.path && assetToken ? (
          <StyledImage
            src={`${cloudinaryThumbnail?.path}?${assetToken}`}
            data-testid="hero-banner-category-image"
          />
        ) : (
          <FallbackWaves
            title={exploreCategoryTitle}
            colourTheme={colourTheme}
            data-testid="fallback-waves"
          />
        )}
      </CardImageContainer>
    </Card>
  );
};

export default HeroBannerCategory;
