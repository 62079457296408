import { HeadingText, BodyText } from '@unmind/design-system-components-web';
import {
  HERO_BANNER_IMAGE_MAX_WIDTH_MOBILE,
  HERO_BANNER_IMAGE_MAX_WIDTH_DESKTOP,
} from 'Explore/constants';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { small, medium } from 'utils';

export const Card = styled.div`
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.background.card};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  flex-wrap: wrap-reverse;

  ${small(css`
    flex-wrap: nowrap;
  `)}
`;

export const CardImageContainer = styled(Link)`
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: ${HERO_BANNER_IMAGE_MAX_WIDTH_MOBILE};
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;

  ${small(css`
    border-top-left-radius: 0;
    border-bottom-right-radius: 19px;
    max-width: 65%;
  `)}

  :hover > :nth-child(2) > :nth-child(1) {
    transform: scale(1.1);
    transition: transform 200ms ease-in-out;
  }

  ${medium(css`
    max-width: ${HERO_BANNER_IMAGE_MAX_WIDTH_DESKTOP};
  `)};
`;

export const StyledImage = styled.img`
  object-fit: cover;
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;
  max-width: ${HERO_BANNER_IMAGE_MAX_WIDTH_MOBILE};
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;

  ${small(css`
    border-top-left-radius: 0;
    border-bottom-right-radius: 19px;
  `)}

  ${medium(css`
    max-width: ${HERO_BANNER_IMAGE_MAX_WIDTH_DESKTOP};
  `)}
`;

export const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;

  ${small(css`
    align-items: flex-start;
    width: unset;
    max-width: 35%;
    padding: 24px;
  `)}
`;

export const Title = styled(HeadingText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize24,
  ],
  weight: theme.typography.fontWeights.semiBold,
  align: 'center',
  level: 2,
}))`
  word-break: break-word;
  margin-bottom: ${rem(4)};
  margin-top: ${rem(16)};

  ${small(css`
    margin-top: ${rem(0)};
    margin-bottom: ${rem(16)};
    text-align: left;
  `)}
`;

export const Subtitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
  ],
  weight: 'regular',
  forwardedAs: 'p',
}))`
  margin-bottom: ${rem(16)};

  ${small(css`
    color: ${({ theme }) => theme.colors.text.primary};
    line-height: 1.4;
  `)}
`;

export const TextWrapper = styled.div`
  text-align: center;

  ${small(css`
    text-align: left;
    margin-right: ${rem(22)};
  `)}
`;
