import { gql, useQuery } from '@apollo/client';
import { helpResourcesFolders } from './__generated__/helpResourcesFolders';

export const HELP_RESOURCES_FOLDERS_QUERY = gql`
  query helpResourcesFolders {
    helpContentResourceRoot {
      title
      id
    }
  }
`;

export const useHelpResourcesFolders = () => {
  const { data, loading, error, refetch } = useQuery<helpResourcesFolders>(
    HELP_RESOURCES_FOLDERS_QUERY,
    {
      errorPolicy: 'all',
      returnPartialData: false,
    },
  );

  return { data, loading, error, refetch };
};
