import React from 'react';
import { styled } from 'styles';
import { rem } from 'polished';
import RoutePath from 'App/RoutePath';
import { useTranslation } from 'react-i18next';
import { tracking } from 'App/Tracking';
import { TabHelp } from '../../icons';
import {
  BannerContainer,
  CardWrapper,
  CardActionsContainer,
  CardContentContainer,
  CardImageContainer,
  TextWrapper,
  Title,
  Subtitle,
  SupportContainer,
  StyledImage,
  SupportButton,
  SupportTitle,
  SupportButtonText,
  SupportDescription,
  BrowseButton,
  HelpPhoneIcon,
} from './HelpBanner.styled';

export const StyledHelpIcon = styled(TabHelp)`
  height: ${rem(20)};
  width: ${rem(20)};
  align-self: center;
`;

type HelpBannerProps = {
  assetToken: string;
};

const HelpBanner = ({ assetToken }: HelpBannerProps) => {
  const { t: translate } = useTranslation('help', {
    keyPrefix: 'homepage.banner',
  });

  return (
    <BannerContainer>
      <CardWrapper>
        <CardContentContainer>
          <TextWrapper>
            <Title level={2}>{translate('title')}</Title>
            <Subtitle>{translate('description')}</Subtitle>
          </TextWrapper>
          <CardActionsContainer>
            <BrowseButton
              label={translate('browse_button')}
              to={{
                pathname: RoutePath.Talk,
              }}
              onClick={() => {
                tracking.track('help_homepage_talk_clicked');
              }}
            />
          </CardActionsContainer>
        </CardContentContainer>
        <CardImageContainer
          to={{
            pathname: RoutePath.Talk,
          }}
          tabIndex={-1}
        >
          <StyledImage
            src={`https://assets.unmind.com/image/upload/v1731316082/talk_help_homepage_banner.png?${assetToken}`}
          />
        </CardImageContainer>
      </CardWrapper>
      <SupportContainer>
        <SupportTitle level={2}>
          <StyledHelpIcon />
          {translate('support.title')}
        </SupportTitle>
        <SupportDescription>
          {translate('support.description')}
        </SupportDescription>
        <SupportButton
          label={
            <>
              <SupportButtonText aria-label={translate('support.a11y_label')}>
                {translate('support.button')}
              </SupportButtonText>
              <HelpPhoneIcon />
            </>
          }
          to={{
            pathname: `${RoutePath.Help}`,
          }}
          onClick={() => {
            tracking.track('help_homepage_service_clicked', {
              serviceId: '0',
              serviceName: 'help-homepage-banner',
            });
          }}
        />
      </SupportContainer>
    </BannerContainer>
  );
};

export default HelpBanner;
