import { LDContext } from 'launchdarkly-js-sdk-common';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { getMSTeamsUserContext, isMSTeams } from 'utils/MSTeams';
import { getUserLocale } from '../i18n/getUserLocale';
import getSubdomainFromUrl from '../utils/getSubdomainFromUrl';
import { FeatureFlagUserAttrs, WithFeatureFlagUserContextProps } from './types';
import { FeatureFlag } from './useFeatureFlag';

export const LAUNCH_DARKLY_USER_KEY = 'launch-darkly-user';

/*
  The Launch Darkly SDK is slightly different between React and React Native.
  In the React Native SDK, a key is required for both anonymous and non-anonymous users.
  In the React SDK, the key can be omitted when anonymous is true.
*/
const withFeatureFlagUserContext =
  <P extends Record<string, unknown>>(
    WrappedComponent: React.ComponentType<P & WithFeatureFlagUserContextProps>,
  ) =>
  (props: P) => {
    const client = useLDClient();

    const anonymizeFeatureFlagUser = async () => {
      if (client) {
        localStorage.removeItem(LAUNCH_DARKLY_USER_KEY);
        const subdomain = getSubdomainFromUrl();
        const teamsContext = getMSTeamsUserContext();
        const teamsTenantId = isMSTeams()
          ? teamsContext?.user?.tenant?.id
          : null;
        const userData: LDContext = {
          kind: 'user',
          anonymous: true,
          subdomain,
          ...(teamsTenantId && { tenantId: teamsTenantId }),
        };

        localStorage.setItem(LAUNCH_DARKLY_USER_KEY, JSON.stringify(userData));

        await client.waitForInitialization();
        // In client-side LaunchDarkly SDKs, the identify feature allows you to change
        // user context as they are configured to operate for one user at a time
        await client.identify(userData);
      } else {
        console.info('LDClient not available');
      }
    };

    const identifyFeatureFlagUser = async ({
      subdomain,
      userId,
      userTraits = [''],
      tenantId,
      locationId,
      departmentId,
    }: FeatureFlagUserAttrs) => {
      if (client) {
        const locale = getUserLocale();

        const customAttrs = {
          subdomain,
          userTraits,
          locale,
          tenantId,
          locationId,
          departmentId,
        };

        const userData: LDContext = {
          kind: 'user',
          anonymous: false,
          key: userId,
          ...customAttrs,
        };

        localStorage.setItem(LAUNCH_DARKLY_USER_KEY, JSON.stringify(userData));
        await client.waitForInitialization();
        await client.identify(userData);
      } else {
        console.info('LDClient not available');
      }
    };

    const getFeatureFlag = (flag: FeatureFlag): boolean => {
      if (!client) {
        console.info('LDClient not available');

        return false;
      }

      const allFlags = client.allFlags();

      return allFlags[flag];
    };

    return (
      <WrappedComponent
        identifyFeatureFlagUser={identifyFeatureFlagUser}
        anonymizeFeatureFlagUser={anonymizeFeatureFlagUser}
        getFeatureFlag={getFeatureFlag}
        {...props}
      />
    );
  };

export default withFeatureFlagUserContext;
