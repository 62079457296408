import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { css, styled } from 'styles';
import { rem } from 'polished';
import { large, small } from 'utils';
import { AddToCalendar } from 'Shared/AddToCalendar';
import { getToolSummaryFromHtml } from 'utils/getToolSummaryFromHtml';
import RoutePath from '../../../App/RoutePath';
import { tracking } from '../../../App/Tracking';
import { getBreadcrumbStateInfo } from '../../../App/routeHelpers';
import { DurationText } from '../../../Courses/CourseCard';
import { CourseProgressPill as ShortPill } from '../../../Courses/CourseProgress';
import {
  Card,
  CardContentContainer,
  CardImageContainer,
  StyledImage,
  Subtitle,
  TextWrapper,
  Title,
} from '../styles';
import { ScreenReaderContent } from '../../../Shared/Accessibility';
import ArrowButton from '../../../Shared/ArrowButton';
import {
  CardActionContainer,
  CardMediaTypeIcon,
} from '../../../Shorts/ShortCard';
import PlayButton from '../../../Shorts/ShortCardButtons/PlayButton';
import { getToolMediaTypeIcon } from '../../../Tools/Cards/helpers/toolCardHelpers';
import FallbackWaves from '../../FallbackWaves/FallbackWaves';
import { ContentType, Tool } from '../../types';

const CardActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${rem(130)};
  align-items: center;

  ${large(css`
    width: auto;
    width: 100%;
    max-width: ${rem(185)};
  `)}

  ${small(css`
    align-items: normal;
  `)}
`;

const CalendarContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: ${rem(8)};
`;

export interface HeroBannerProps {
  tool: Tool;
  assetToken?: string | null;
  source: string;
}

const HeroBannerShort = ({ tool, assetToken, source }: HeroBannerProps) => {
  const { t: translate } = useTranslation('explore');
  const location = useLocation();
  const breadcrumbState = getBreadcrumbStateInfo(location);

  const {
    id,
    title,
    slug,
    summary,
    html,
    mediaType,
    estimatedMins,
    cloudinaryThumbnail,
    exploreCategory,
    isFavourite,
  } = tool;

  const mediaTypeIcon = getToolMediaTypeIcon(tool?.mediaType?.value);
  const buttonText =
    mediaType?.value === 'audio'
      ? translate('featured_hero_banner.short.cta_button.audio.text')
      : translate('featured_hero_banner.short.cta_button.video.text');

  const trackShortClick = () => {
    tracking.track('content-selected', {
      contentId: id,
      contentSlug: slug,
      contentDuration: estimatedMins,
      contentType: ContentType.short,
      source,
      contentCategoryPath: exploreCategory?.internalName,
      isFavourite: isFavourite,
    });
  };

  const toolMediaPlayerPath = `${RoutePath.Shorts}/${slug}`;
  const onNavigateToShort = () => {
    trackShortClick();
  };

  // In Core API we are mapping the contentful short differently depending on whether it is featured or not
  // https://unmindteam.slack.com/archives/C01BKR017QV/p1702467401320039
  const toolSummary = (html && getToolSummaryFromHtml(html)) || summary;

  const showAddToCalendar = toolSummary && title;

  return (
    <Card data-testid="hero-banner-short">
      <CardContentContainer>
        <TextWrapper>
          <Title>{title}</Title>
          <Subtitle>{toolSummary}</Subtitle>
        </TextWrapper>
        <CardActionsContainer>
          <ArrowButton
            text={buttonText}
            to={{
              pathname: toolMediaPlayerPath,
              state: {
                ...breadcrumbState,
                onToolPlayerClose: {
                  location: {
                    pathname: location.pathname,
                  },
                },
              },
            }}
            onClick={onNavigateToShort}
            enableMobileWebLink={true}
          />
          {showAddToCalendar && (
            <CalendarContainer>
              <AddToCalendar
                description={toolSummary}
                contentPath={toolMediaPlayerPath}
                title={title}
                isKeyDownListenerEnabled={false}
                buttonTextKey={'add_to_calendar.text'}
              />
            </CalendarContainer>
          )}
        </CardActionsContainer>
      </CardContentContainer>
      <CardImageContainer
        to={{
          pathname: toolMediaPlayerPath,
          state: {
            ...breadcrumbState,
            onToolPlayerClose: {
              location: {
                pathname: location.pathname,
              },
            },
          },
        }}
        onClick={onNavigateToShort}
      >
        {cloudinaryThumbnail?.path && assetToken ? (
          <StyledImage
            src={`${cloudinaryThumbnail?.path}?${assetToken}`}
            data-testid="hero-banner-short-image"
          />
        ) : (
          <FallbackWaves
            title={title as string}
            colourTheme={exploreCategory?.colourTheme as string}
            data-testid="fallback-waves"
          />
        )}
        <CardActionContainer>
          <PlayButton />
        </CardActionContainer>
        <ShortPill>
          {mediaTypeIcon ? (
            <>
              <CardMediaTypeIcon Icon={mediaTypeIcon} />
              <ScreenReaderContent as="span">
                {mediaType?.value}
              </ScreenReaderContent>
            </>
          ) : null}
          <DurationText>
            {translate('featured_hero_banner.short.duration_text', {
              duration: estimatedMins?.toString(),
            })}
          </DurationText>
        </ShortPill>
      </CardImageContainer>
    </Card>
  );
};

export default HeroBannerShort;
