import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { css, styled } from 'styles';
import { Course } from 'icons';
import { AddToCalendar } from 'Shared/AddToCalendar';
import RoutePath from '../../../App/RoutePath';
import { tracking } from '../../../App/Tracking';
import CourseProgress from '../../../Courses/CourseProgress';
import {
  CourseIcon,
  CourseText,
  DurationText,
} from '../../../Courses/CourseCard';
import {
  Card,
  CardContentContainer,
  StyledImage,
  Subtitle,
  TextWrapper,
  Title,
} from '../styles';
import ArrowButton from '../../../Shared/ArrowButton';
import { small, medium } from '../../../utils';
import {
  HERO_BANNER_IMAGE_MAX_WIDTH_DESKTOP,
  HERO_BANNER_IMAGE_MAX_WIDTH_MOBILE,
} from '../../constants';
import FallbackWaves from '../../FallbackWaves/FallbackWaves';
import { ContentType, Series } from '../../types';

const CardImageContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: ${HERO_BANNER_IMAGE_MAX_WIDTH_MOBILE};
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;

  ${small(css`
    border-top-left-radius: 0;
    border-bottom-right-radius: 19px;
    max-width: 65%;
  `)}

  ${medium(css`
    max-width: ${HERO_BANNER_IMAGE_MAX_WIDTH_DESKTOP};
  `)};
`;

const CoursePillsWrapper = styled.div`
  position: absolute;
  right: ${rem(6)};
  bottom: ${rem(6)};
  display: flex;

  ${small(css`
    right: 24px;
    bottom: 24px;
  `)}
`;

const CoursePill = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${rem(20)};
  background-color: ${({ theme }) => theme.colors.background.card};
  margin-left: ${rem(6)};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04);
  padding: ${rem(3)} ${rem(6)};
`;

const DurationTextBanner = styled(DurationText)`
  margin-left: ${rem(0)};
`;

const CalendarContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: ${rem(8)};
`;

const CardActionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${small(css`
    align-items: normal;
  `)}
`;

interface HeroBannerProps {
  series: Series;
  assetToken?: string | null;
  source: string;
}

const HeroBannerCourse = ({ series, assetToken, source }: HeroBannerProps) => {
  const { t: translate } = useTranslation<Namespace<'explore' | 'courses'>>([
    'explore',
    'courses',
  ]);

  const {
    id,
    title,
    slug,
    subtitle,
    cloudinaryThumbnail,
    daysCompleted,
    totalDays,
    exploreCategory,
  } = series;

  const trackCourseClick = () => {
    tracking.track('content-selected', {
      contentId: id,
      contentSlug: slug,
      contentDuration: totalDays,
      contentType: ContentType.course,
      source,
      contentCategoryPath: exploreCategory?.internalName,
      isFavourite: null,
    });
  };

  const coursePath = `${RoutePath.Courses}/${slug}`;
  const onNavigateCourse = () => {
    trackCourseClick();
  };

  return (
    <Card data-testid="hero-banner-course">
      <CardContentContainer>
        <TextWrapper>
          <Title tabIndex={0}>{title}</Title>
          <Subtitle tabIndex={0}>{subtitle}</Subtitle>
        </TextWrapper>
        <CardActionsContainer>
          <ArrowButton
            text={translate(
              'explore:featured_hero_banner.course.cta_button.text',
            )}
            to={{ pathname: coursePath }}
            onClick={onNavigateCourse}
            enableMobileWebLink={true}
          />
          {title && subtitle && (
            <CalendarContainer>
              <AddToCalendar
                title={title}
                contentPath={coursePath}
                description={subtitle}
                buttonTextKey={'add_to_calendar.text'}
              ></AddToCalendar>
            </CalendarContainer>
          )}
        </CardActionsContainer>
      </CardContentContainer>
      <CardImageContainer>
        {cloudinaryThumbnail?.path && assetToken ? (
          <StyledImage
            src={`${cloudinaryThumbnail?.path}?${assetToken}`}
            data-testid="hero-banner-course-image"
          />
        ) : (
          <FallbackWaves
            title={title as string}
            colourTheme={exploreCategory?.colourTheme as string}
            data-testid="fallback-waves"
          />
        )}
        <CoursePillsWrapper>
          <CoursePill>
            <CourseIcon Icon={Course} />
            <CourseText>
              {translate('courses:course_card.course_pill.text')}
            </CourseText>
          </CoursePill>
          {totalDays ? (
            <CoursePill>
              <DurationTextBanner>
                {translate('courses:course_card.course_duration_text', {
                  total_days: totalDays,
                })}
              </DurationTextBanner>
            </CoursePill>
          ) : null}
          <CourseProgress
            daysCompleted={daysCompleted}
            totalDays={totalDays}
            grouped={true}
          />
        </CoursePillsWrapper>
      </CardImageContainer>
    </Card>
  );
};

export default HeroBannerCourse;
