import { useMutation } from '@apollo/client';
import { CreateInvitedUserInput } from '__generated__/globalTypes';
import { useHistory, useLocation } from 'react-router-dom';
import gql from 'graphql-tag';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SIGNUP_BASE_PATH } from '../SignUp';

export const CREATE_INVITED_USER_MUTATION = gql`
  mutation createInvitedUser($input: CreateInvitedUserInput!) {
    createInvitedUser(input: $input) {
      id
      inactive
    }
  }
`;

export const useCreateInvitedUser = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [error, setError] = useState('');
  const { t: translate } = useTranslation('logged_out');

  const [mutateCreateInvitedUser] = useMutation(CREATE_INVITED_USER_MUTATION, {
    onCompleted: () =>
      history.push(`${SIGNUP_BASE_PATH}/confirmation${search}`),
    onError: () => {
      setError(translate('login.errors.default_login_error'));
    },
  });

  const createInvitedUser = async (input: CreateInvitedUserInput) => {
    await mutateCreateInvitedUser({ variables: { input: { ...input } } });
  };

  return { createInvitedUser, error };
};
