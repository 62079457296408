import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { MutationFunction } from '@apollo/client';
import { Errors } from '../../../Shared/Errors';
import { COURSE_QUERY } from '../CourseOverview/useSingleSeriesWithSegments';
import { withSeriesDayProgressQuery as SeriesDayProgressQuery } from './withSeriesDayProgress';

export const withLogSeriesActivityMutation = gql`
  mutation withLogSeriesActivityMutation(
    $segmentId: String!
    $dayId: String!
    $seriesId: String!
    $seconds: Int!
    $clientSentAtUtcTimestamp: String
    $exploreCategoryId: String
  ) {
    logSeriesActivity(
      segmentId: $segmentId
      dayId: $dayId
      seriesId: $seriesId
      seconds: $seconds
      clientSentAtUtcTimestamp: $clientSentAtUtcTimestamp
      exploreCategoryId: $exploreCategoryId
    )
  }
`;

export interface WithLogSeriesActivityApiResponse {
  logSeriesActivity: boolean;
}

export interface WithLogSeriesActivityResponsesChildProps {
  logSeriesActivity(
    variables: WithLogSeriesActivityVariables,
  ): Promise<WithLogSeriesActivityResponse>;
}

export interface WithLogSeriesActivityVariables {
  segmentId: string;
  dayId: string;
  seriesId: string;
  seconds: number;
  slug: string;
  clientSentAtUtcTimestamp: string;
  exploreCategoryId?: string | null;
}

export interface WithLogSeriesActivityResponse {
  success: boolean;
  error?: Errors;
}

export const logSeriesActivity = async (
  variables: WithLogSeriesActivityVariables,
  mutate?: MutationFunction<
    WithLogSeriesActivityApiResponse,
    WithLogSeriesActivityVariables
  >,
) => {
  try {
    if (mutate) {
      const response = await mutate({
        refetchQueries: [
          {
            query: SeriesDayProgressQuery,
            variables: {
              ...variables,
              id: variables.dayId,
            },
          },
          {
            query: COURSE_QUERY,
            variables: {
              slug: variables.slug,
            },
          },
        ],
        awaitRefetchQueries: true,
        variables: variables,
      });

      if (response && response.data) {
        return {
          success: response.data.logSeriesActivity,
        };
      }
    }
  } catch (e) {
    console.error(e); // to Sentry
  }

  return {
    success: false,
    error: Errors.ServerError,
  };
};

/**
 * @deprecated
 * This has been replaced by the useRecordSeriesSegmentCompleted hook
 * which returns a response we can use to update the cache.
 * Use that where appropriate instead of this.
 */
export const withLogSeriesActivity = graphql<
  Record<string, unknown>,
  WithLogSeriesActivityApiResponse,
  WithLogSeriesActivityVariables,
  WithLogSeriesActivityResponsesChildProps
>(withLogSeriesActivityMutation, {
  props: ({ mutate }) => ({
    logSeriesActivity: async (variables: WithLogSeriesActivityVariables) =>
      logSeriesActivity(variables, mutate),
  }),
});
