import React from 'react';
import { useLocation } from 'react-router';
import { Container, Loader } from '@unmind/design-system-components-web';
import Route404 from 'App/Router/Route404';
import styled from 'styled-components';
import { rem } from 'polished';
import RoutePath from '../../../App/RoutePath';
import { CourseHeader } from './components/CourseHeader';
import { NextSessionLink } from './components/NextSessionLink';
import { CourseContent } from './components/CourseContent';
import { CourseDescription } from './components/CourseDescription';
import { useSingleSeriesWithSegments } from './useSingleSeriesWithSegments';
import { Container as CourseContentContainer } from './components/styles';
import { CourseProgress } from './components/CourseProgress';
import { GetSingleSeries_singleSeries_seriesDays as SeriesDay } from './__generated__/GetSingleSeries';

const CourseProgressContainer = styled.div`
  margin-bottom: ${rem(32)};
`;

export const getSessionTitle = (
  seriesDays: (SeriesDay | null)[],
  sessionNumber: number,
): string | null => {
  if (!seriesDays || seriesDays.length === 0) {
    return '';
  }

  const matchedSession = seriesDays.find(
    session => session?.orderNo === sessionNumber,
  );

  if (!matchedSession) {
    return '';
  }

  const { title } = matchedSession;

  return title;
};

export const CourseOverview = () => {
  const { pathname } = useLocation();

  const slug = pathname.split('/')[2];

  const { seriesData, loading, error } = useSingleSeriesWithSegments(slug);

  const totalSessions = seriesData?.totalDays || 0;
  const daysCompleted = seriesData?.daysCompleted || 0;
  const currentSession = daysCompleted + 1;
  const courseComplete = daysCompleted === totalSessions;

  const activeDayLinkPath = `${RoutePath.Series}/${slug}/day/${currentSession}`;

  if (!seriesData && !loading && !error) {
    return <Route404 />;
  }

  const currentSessionTitle =
    (seriesData?.seriesDays &&
      getSessionTitle(seriesData.seriesDays, currentSession)) ||
    '';

  const benefits = [
    seriesData?.benefit1?.stringValue,
    seriesData?.benefit2?.stringValue,
    seriesData?.benefit3?.stringValue,
  ].filter(Boolean) as string[];

  const validSeriesSessions = seriesData?.seriesDays?.filter(
    session => session !== null,
  ) as SeriesDay[];

  const minsPerDay = seriesData?.minsPerDay?.numericValue || 0;

  return (
    <Container>
      {loading && <Loader />}
      {seriesData && (
        <>
          {seriesData.subtitle && seriesData.title && (
            <CourseHeader
              subtitle={seriesData?.subtitle}
              title={seriesData?.title}
              isNotAnonymised={seriesData?.isNotAnonymised || false}
            />
          )}
          <CourseContentContainer>
            <>
              <CourseProgressContainer>
                <CourseProgress
                  totalDays={totalSessions}
                  daysCompleted={seriesData?.daysCompleted || 0}
                />
              </CourseProgressContainer>
              {!courseComplete && (
                <NextSessionLink
                  title={currentSessionTitle}
                  link={activeDayLinkPath}
                  sessionNumber={currentSession}
                />
              )}
            </>
            {seriesData?.description?.longValue && benefits && (
              <CourseDescription
                description={seriesData.description.longValue}
                benefits={benefits}
              />
            )}
            {validSeriesSessions && validSeriesSessions.length > 0 && (
              <CourseContent
                minsPerDay={minsPerDay}
                sessions={validSeriesSessions}
                activeSessionTitle={currentSessionTitle}
                slug={slug}
              />
            )}
          </CourseContentContainer>
        </>
      )}
    </Container>
  );
};
