import React from 'react';
import { styled } from 'styles';
import { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import {
  HeadingText,
  BodyText,
  Alert,
  extraSmall,
} from '@unmind/design-system-components-web';
import RoutePath from '../App/RoutePath';
import { tracking } from '../App/Tracking';
import LoadingIndicator from '../Shared/LoadingIndicator';
import SecondaryButton from '../Shared/SecondaryButton';
import { useHelpResourcesFolders } from './hooks/useHelpResourcesFolders';

const ResourcesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${rem(42)};
  padding: ${rem(40)};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: ${rem(20)};
  width: 100%;
  margin-bottom: ${rem(56)};
`;

const ResourcesTitle = styled(HeadingText).attrs(({ theme }) => ({
  level: 2,
  sizes: [theme.typography.fontSizes.fontSize18],
  weight: theme.typography.fontWeights.semiBold,
}))``;

const ResourceItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  gap: ${rem(40)};

  ${extraSmall(css`
    flex-direction: row;
  `)}
`;

const ResourceItem = styled(Link)`
  color: ${({ theme }) => theme.colors.text.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const ResourceName = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
}))`
  text-decoration-line: underline;
  text-align: center;
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
`;

const AlertContainer = styled.div`
  max-width: ${rem(680)};
  align-self: center;
`;

const RetryOnErrorButton = styled(SecondaryButton).attrs(() => ({
  size: 'small',
}))`
  margin-left: ${rem(12)};
  background: ${({ theme }) => theme.colors.background.card};
  min-width: ${rem(96)};

  &:hover {
    background: ${({ theme }) => theme.colors.background.cardHover};
  }
`;

const trackResourceLinkClick = ({
  id,
  title,
  countryCode,
}: {
  id: string;
  title: string;
  countryCode: string;
}) => {
  tracking.track('help_landing_resource_clicked', {
    resourceId: id,
    resourceTitle: title,
    countryCode,
  });
};

const HelpResources = ({ countryCode }: { countryCode: string }) => {
  const { t: translate } = useTranslation('help');

  const { data, loading, error, refetch } = useHelpResourcesFolders();

  if (loading) {
    return <LoadingIndicator />;
  }

  const helpFolders = data?.helpContentResourceRoot || [];

  if (error || !helpFolders.length) {
    return (
      <ResourcesContainer>
        <ResourcesTitle>{translate('help_resources.title')}</ResourcesTitle>
        <AlertContainer>
          <Alert
            variant="error"
            title={translate('help_service_tabs.error_state.title')}
            body={translate('help_service_tabs.error_state.body')}
            size="small"
          >
            <RetryOnErrorButton
              label={translate(
                'help_service_tabs.error_state.refetch_button_label',
              )}
              onClick={async () => refetch()}
            />
          </Alert>
        </AlertContainer>
      </ResourcesContainer>
    );
  }

  return (
    <ResourcesContainer>
      <ResourcesTitle>{translate('help_resources.title')}</ResourcesTitle>
      <ResourceItemContainer>
        {helpFolders.map(({ title, id }) => (
          <ResourceItem
            key={id}
            to={`${RoutePath.Help}/${id}`}
            onClick={() => {
              trackResourceLinkClick({ id, title, countryCode });
            }}
          >
            <ResourceName>{title}</ResourceName>
          </ResourceItem>
        ))}
      </ResourceItemContainer>
    </ResourcesContainer>
  );
};

export default HelpResources;
