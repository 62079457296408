import React from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { BodyText } from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useTheme } from 'styles';
import { IconTextWrapper } from 'Talk/components/TalkHeader/styles';
import { useTalkUserSessionLimits } from 'Talk/hooks/useTalkUserSessionLimits';
import { ProgressBarContainer } from './styles';

const CircularProgressbarWithStyles = ({
  amountRemaining,
  total,
}: {
  amountRemaining: number;
  total: number;
}) => {
  const theme = useTheme();
  const CLOCKWISE_ROTATION_TO_OFFSET_ROUNDED_EDGES = 0.01;

  return (
    <ProgressBarContainer>
      <CircularProgressbar
        value={amountRemaining}
        strokeWidth={16}
        maxValue={total}
        styles={buildStyles({
          pathColor: theme.colors.text.success,
          trailColor: theme.colors.background.secondary,
          strokeLinecap: 'round',
          rotation: CLOCKWISE_ROTATION_TO_OFFSET_ROUNDED_EDGES,
        })}
        counterClockwise={true}
      />
    </ProgressBarContainer>
  );
};

const SessionLimitText = ({
  amountRemaining,
  total,
}: {
  amountRemaining: number;
  total: number;
}) => {
  const { t } = useTranslation<Namespace<'talk'>>('talk');

  if (amountRemaining < -1) return null;
  else if (amountRemaining === -1 || total === 52) {
    return (
      <BodyText sizes={[FontSizes.fontSize14, FontSizes.fontSize16]}>
        {t('onboarding_notification.session_limits_unlimited')}
      </BodyText>
    );
  }

  return (
    <BodyText sizes={[FontSizes.fontSize14, FontSizes.fontSize16]}>
      {t('onboarding_notification.sessions_remaining', {
        remaining: amountRemaining,
        total,
      })}
    </BodyText>
  );
};

export const LimitsHeader = () => {
  const { sessionLimit, remainingSessions, loading, error } =
    useTalkUserSessionLimits();

  if (loading || error) return null;

  return (
    <IconTextWrapper>
      <CircularProgressbarWithStyles
        amountRemaining={remainingSessions}
        total={sessionLimit}
      />
      <BodyText sizes={[FontSizes.fontSize14, FontSizes.fontSize16]}>
        <SessionLimitText
          amountRemaining={remainingSessions}
          total={sessionLimit}
        />
      </BodyText>
    </IconTextWrapper>
  );
};
