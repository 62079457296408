import React from 'react';
import { styled, css } from 'styles';
import { rem } from 'polished';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import { compose } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import PrimaryButton from '../../Shared/PrimaryButton';
import { AppStore } from '../AppStore/AppStore';
import {
  AuthWrapperBlock,
  AuthWrapperSerifTitle,
  createTextContentWidth,
  AuthWrapper,
} from '../AuthWrapper';
import { medium } from '../../utils';
import RoutePath from '../../App/RoutePath';
import { ChildProps, withSubdomainFromUrl } from '../withSubdomainFromUrl';
import { tracking } from '../../App/Tracking';
import Loader from '../../Shared/Loader';
import RedirectIfUnauthenticated from '../../App/Router/RedirectIfUnauthenticated';
import { USER_QUERY } from '../../Account/withUser';
import { useIsVirginPulseGroup } from '../VirginPulse/useIsVirginPulseGroup';
import { HeadingText } from '../../Shared/Typography';

const AuthButton = styled(PrimaryButton)`
  margin-top: auto;
  width: 100%;

  ${medium(css`
    margin-top: ${rem(24)};
  `)}
`;

const Container = styled(AuthWrapperBlock)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DesktopAppStoreBadgesWrapper = styled.div`
  padding-top: ${rem(24)};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(AuthWrapperSerifTitle).attrs(({ theme }) => ({
  weight: theme.typography.fontWeights.regular,
}))`
  align-self: center;
  text-align: center;
`;

const ContentSubtitle = styled(HeadingText).attrs(({ theme }) => ({
  level: 2,
  sizes: [
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
  ],
}))`
  ${createTextContentWidth()};
`;

// note: in the layers above, where we do rendering of headers
// this page sits in a limbo: the user is authenticated at this point
// but we expect the header to be the usual "unauthenticated" header - not the menu bar
// therefore we don't make the query to check if a user is authenticated here
const WelcomeToUnmind: React.FC<ChildProps> = ({ subdomain }) => {
  const history = useHistory();
  const { t: translate } = useTranslation('logged_out');

  const { data, loading: loadingUserQuery } = useQuery(USER_QUERY);
  const { loading: loadingVirginPulseQuery, isVirginPulseGroup } =
    useIsVirginPulseGroup();
  const isChatBotEnabled = useFeatureFlag(FEATURE_FLAGS.ENABLE_CHATBOT);

  const onContinueClick = () => {
    tracking.track('start-unmind-clicked', {
      subdomain,
    });
    history.push(isChatBotEnabled ? RoutePath.ChatbotLanding : RoutePath.Home);
  };

  if (loadingUserQuery || loadingVirginPulseQuery) {
    return <Loader />;
  }

  if (!loadingUserQuery && !data) {
    return <RedirectIfUnauthenticated to="/" />;
  }

  const {
    user: { firstName },
  } = data;

  return (
    <AuthWrapper showFooter={false}>
      <Container>
        <Title>
          {translate('sign_up.welcome_screen.heading', {
            first_name: firstName,
          })}
        </Title>
        {Boolean(isVirginPulseGroup) && (
          <ContentSubtitle>
            {translate('sign_up.welcome_screen.virgin_pulse_subtitle')}
          </ContentSubtitle>
        )}
        <DesktopAppStoreBadgesWrapper>
          <AppStore helpText={translate('sign_up.app_store.heading')} />
        </DesktopAppStoreBadgesWrapper>
        <AuthButton
          data-testid="get-started-button"
          label={translate('sign_up.welcome_screen.get_started_button.label')}
          onClick={onContinueClick}
        />
      </Container>
    </AuthWrapper>
  );
};

export default compose(withSubdomainFromUrl)(WelcomeToUnmind);
