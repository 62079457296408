import { useQuery } from '@apollo/client';
import { exploreFeaturedContent } from 'Explore/__generated__/exploreFeaturedContent';
import { FEATURED_CONTENT_QUERY } from 'Explore/explorePageQueries';
import React from 'react';
import styled from 'styled-components';
import LoadingIndicator from 'Shared/LoadingIndicator';
import HelpBanner from './HelpBanner';

const HomepageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const HomepageBanner = styled.div`
  display: flex;
  margin-bottom: 100px;
`;

export const HelpHomepage = () => {
  const { data: featuredContentData, loading: featuredContentLoading } =
    useQuery<exploreFeaturedContent>(FEATURED_CONTENT_QUERY, {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      returnPartialData: true,
      notifyOnNetworkStatusChange: true,
    });

  if (featuredContentLoading) {
    return <LoadingIndicator />;
  }

  return (
    <HomepageContainer>
      <HomepageBanner>
        <HelpBanner
          assetToken={featuredContentData?.getAssetToken?.assetToken ?? ''}
        />
      </HomepageBanner>
    </HomepageContainer>
  );
};
