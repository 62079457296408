import { BodyText, Button } from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import { rgba } from 'polished';
import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { animated, useTransition } from 'react-spring';
import Portal from 'Shared/Portal/Portal';
import { styled } from 'styles';
import { useTalkUserSessionLimits, useUnmindTalk } from 'Talk/hooks';
import RoutePath from '../../../App/RoutePath';
import { tracking } from '../../../App/Tracking';
import { useAssignFocus } from '../../../Shared/Accessibility';
import { default as BackgroundImage } from './assets/background-image.jpg';
import { BenefitsCard } from './BenefitsCard';
import {
  getUserHasSeenTalkOnboardingModal,
  setUserHasSeenTalkOnboardingModal,
} from './constants';

const ModalTitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize24],
  weight: theme.typography.fontWeights.medium,
  color: theme.colors.staticPalette.white,
  align: 'center',
}))`
  &:focus {
    outline: none;
  }
`;

const ModalCopy = styled(ModalTitle).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize18],
  weight: theme.typography.fontWeights.regular,
}))``;

const ModalCard = styled(animated.div)`
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1)),
    url(${BackgroundImage}) no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: ${({ theme }) => `0px 8px 16px -4px ${theme.colors.boxShadow}`};
  display: flex;
  flex-direction: column;
  justify-self: center;
  position: relative;
  width: 470px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: scroll;
  padding: 24px;
  cursor: auto;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
`;

const ModalContainer = styled(animated.div)`
  align-content: center;
  align-items: center;
  background: ${({ theme }) => rgba(theme.colors.background.inverse, 0.7)};
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.overMobileNav};
  height: 100%;
  cursor: pointer;
`;

const ModalHeader = styled.div`
  margin-bottom: 170px;
`;

const CTAContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 24px;

  > button,
  a {
    flex-grow: 1;
  }
`;

export type ModalProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Optional HTML Element ref, which will be used to target focus after
   * modal is closed, use for accessibility purposes.
   */
  returnFocusTo?: React.MutableRefObject<HTMLElement | null>;
};

const useModalState = (initialShowState: boolean = false) => {
  const [show, setShow] = useState(initialShowState);
  const hasTabbed = useRef<boolean>(false);

  return {
    show,
    setShow,
    openModal: () => {
      hasTabbed.current = false;
      setShow(true);
    },
    closeModal: () => {
      setShow(false);
    },
    hasTabbed,
  };
};

export const TalkOnboardingModal = ({ returnFocusTo }: ModalProps) => {
  const { t: translate } = useTranslation<Namespace<'talk'>>('talk');

  const { unmindTalkIsAvailableForUser } = useUnmindTalk();
  const featureFlagOn = useFeatureFlag(
    FEATURE_FLAGS.SHOW_TALK_ONBOARDING_MODAL,
  );
  const headerRef = useRef<HTMLHeadingElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const { show, closeModal, hasTabbed, setShow } = useModalState(false);

  const modalTransitions = useTransition(show, null, {
    enter: { opacity: 1, transform: 'scale3d(1, 1, 1)' },
    from: { opacity: 0, transform: 'scale3d(0.7, 0.7, 1)' },
    leave: { opacity: 0, transform: 'scale3d(0.7, 0.7, 1)' },
    config: { duration: 150 },
  });

  const shouldShowTalkOnboardingModal =
    !getUserHasSeenTalkOnboardingModal() &&
    featureFlagOn &&
    unmindTalkIsAvailableForUser;

  useEffect(() => {
    if (shouldShowTalkOnboardingModal) {
      setShow(true);
      setUserHasSeenTalkOnboardingModal(true);
      tracking.track('talk-onboarding-notification-modal-viewed');
    }
  }, [shouldShowTalkOnboardingModal, setShow]);

  useAssignFocus(headerRef, show);

  const { sessionLimit, remainingSessions: amountRemaining } =
    useTalkUserSessionLimits();

  const userHasUnlimitedSessions = sessionLimit === -1 || sessionLimit === 52;

  const handleTabKey = (e: KeyboardEvent): void => {
    if (modalRef.current !== null) {
      const modalElements = modalRef.current.querySelectorAll<HTMLElement>(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      );

      if (modalElements.length > 0) {
        const firstElement = modalElements[0];
        const lastElement = modalElements[modalElements.length - 1];

        if (!hasTabbed.current) {
          firstElement.focus();
          hasTabbed.current = true;

          e.preventDefault();

          return;
        }

        if (!e.shiftKey && document.activeElement === lastElement) {
          firstElement.focus();

          e.preventDefault();

          return;
        }

        if (e.shiftKey && document.activeElement === firstElement) {
          lastElement.focus();

          e.preventDefault();

          return;
        }
      }
    }
  };

  const handleEscKey = (e: KeyboardEvent): void => {
    if (returnFocusTo?.current) {
      returnFocusTo.current.focus();
    }
    tracking.track('talk-notification-dismissed');
    closeModal();
    e.preventDefault();
  };

  const handleBackgroundClick = (): void => {
    if (returnFocusTo?.current) {
      returnFocusTo.current.focus();
    }
    tracking.track('talk-notification-dismissed');
    closeModal();
  };

  const onPrimaryCTAClick = () =>
    tracking.track('talk-notification-browse-practitioners-clicked');
  const onDismissClick = () => {
    tracking.track('talk-notification-dismissed');
    closeModal();
  };

  const keyListenersMap = new Map([
    [27, handleEscKey],
    [9, handleTabKey],
  ]);

  useEffect(() => {
    const keyListener = (e: KeyboardEvent) => {
      const listener = keyListenersMap.get(e.keyCode);

      if (listener) {
        listener(e);
      }
    };

    document.addEventListener('keydown', keyListener);

    return () => {
      document.removeEventListener('keydown', keyListener);
    };
  });

  const sessionLimitsModalCopy = userHasUnlimitedSessions ? (
    <ModalCopy>
      {translate('onboarding_notification.session_limits_unlimited')}
    </ModalCopy>
  ) : (
    <ModalCopy>
      {translate('onboarding_notification.session_limits', {
        count: amountRemaining,
      })}
    </ModalCopy>
  );

  return (
    <Portal>
      {modalTransitions.map(
        ({ item: showModal, key, props: { opacity, ...styles } }) =>
          showModal && (
            <ModalContainer
              aria-modal="true"
              aria-labelledby="modal-title"
              aria-describedby="modal-copy"
              key={key}
              onClick={handleBackgroundClick}
              style={{ opacity }}
              role="dialog"
              aria-live="assertive"
            >
              <ModalCard
                onClick={e => {
                  e.stopPropagation();
                }}
                ref={modalRef}
                style={styles}
              >
                <ModalHeader>
                  <ModalTitle id="modal-title" ref={headerRef} tabIndex={-1}>
                    {translate('onboarding_notification.title')}
                  </ModalTitle>
                  <ModalCopy id="modal-copy">
                    {translate('onboarding_notification.body')}
                  </ModalCopy>
                  {amountRemaining ? sessionLimitsModalCopy : null}
                </ModalHeader>

                <BenefitsCard />

                <CTAContainer>
                  <Button
                    label={translate(
                      'onboarding_notification.dismiss_button.label',
                    )}
                    onClick={onDismissClick}
                    backdrop="onDark"
                    size="small"
                  />
                  <Button
                    label={translate(
                      'onboarding_notification.cta_button.label',
                    )}
                    variant="primary"
                    size="small"
                    to={RoutePath.Talk}
                    onClick={onPrimaryCTAClick}
                  />
                </CTAContainer>
              </ModalCard>
            </ModalContainer>
          ),
      )}
    </Portal>
  );
};
