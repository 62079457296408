import { rem } from 'polished';
import { styled } from 'styles';
import { BodyText } from '../Shared/Typography';

export const Heading = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [theme.typography.fontSizes.fontSize16],
  forwardedAs: 'div',
}))`
  line-height: ${rem(20)};
  margin: 0;
`;

export const SubHeading = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
  weight: theme.typography.fontWeights.medium,
}))`
  text-transform: uppercase;
  margin: 0;
`;

export const Feeling = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
  weight: theme.typography.fontWeights.medium,
}))`
  margin: 0;
`;

export const ParagraphSmall = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
}))`
  margin: 0;

  & strong {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

export const ParagraphLarge = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize18],
}))`
  margin: 0;
`;

interface StatisticProps {
  size?: string;
  color: string;
}

export const Statistic = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize18],
}))<StatisticProps>`
  margin: 0;
`;
