import isNil from 'lodash/isNil';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import { withAuthenticationViaGraphQL } from '../Auth';
import { logException } from '../logging';
import RoutePath from '../RoutePath';
import { RouteConfig } from '../routes';
import RouteWithSubRoute from './RouteWithSubRoute';
import {
  withConfirmEmailUpdate,
  WithConfirmEmailUpdateProps,
} from './withConfirmEmailUpdate';

type UseConfirmEmailUpdateProps = Pick<
  RedirectIfAuthenticatedProps,
  'location' | 'history' | 'confirmEmailUpdate'
>;

const useConfirmEmailUpdate = ({
  location,
  history,
  confirmEmailUpdate,
}: UseConfirmEmailUpdateProps) => {
  useEffect(() => {
    const confirmEmailUpdateIfNeeded = async () => {
      const { search } = location;
      const query = queryString.parse(search);
      const { emailToken } = query;

      if (
        !isNil(emailToken) &&
        !Array.isArray(emailToken) &&
        emailToken.length > 0
      ) {
        const response = await confirmEmailUpdate({ emailToken });

        const confirmEmailUpdateSuccess = Boolean(response.success);

        history.push(RoutePath.Account, {
          confirmEmailUpdateSuccess,
          confirmEmailUpdateError: !confirmEmailUpdateSuccess,
        });
      }
    };

    confirmEmailUpdateIfNeeded().catch(e => {
      logException(e);
    });
  }, []);
};

interface RedirectIfAuthenticatedProps
  extends RouteConfig,
    WithConfirmEmailUpdateProps,
    React.Attributes {
  authenticated: boolean;
  authenticating: boolean;
  redirectIfAuthenticated: string;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  renderIfAuthenticating: React.ComponentType<RouteConfig>;
}

export const RedirectIfAuthenticated: React.FC<RedirectIfAuthenticatedProps> =
  ({
    authenticated,
    authenticating,
    redirectIfAuthenticated,
    renderIfAuthenticating: Authenticating,
    location,
    history,
    confirmEmailUpdate,
    ...props
  }) => {
    useConfirmEmailUpdate({ location, history, confirmEmailUpdate });
    const isChatBotEnabled = useFeatureFlag(FEATURE_FLAGS.ENABLE_CHATBOT);
    if (authenticating) {
      return <Authenticating {...props} />;
    }
    if (authenticated) {
      if (redirectIfAuthenticated === RoutePath.Home) {
        return (
          <Redirect
            to={
              isChatBotEnabled
                ? RoutePath.ChatbotLanding
                : redirectIfAuthenticated
            }
          />
        );
      }

      return <Redirect to={redirectIfAuthenticated} />;
    }

    return <RouteWithSubRoute {...props} />;
  };

export default compose<
  RedirectIfAuthenticatedProps,
  Pick<RedirectIfAuthenticatedProps, 'renderIfAuthenticating'>
>(
  withAuthenticationViaGraphQL,
  withConfirmEmailUpdate,
  withRouter,
)(RedirectIfAuthenticated);
