import { Field, FieldProps, Form, Formik, FormikActions } from 'formik';
import debounce from 'lodash/debounce';
import * as moment from 'moment-timezone';
import { rem, tint } from 'polished';
import queryString from 'query-string';
import type { ApolloClient } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import React, { Fragment, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { compose, withProps } from 'recompose';
import { css, styled, ThemeInterface, withTheme } from 'styles';
import { Info, WaveDark, WaveLight } from 'icons';
import {
  StoringDataInEEACheckbox,
  TermsCheckbox,
  ExplicitConsentCheckbox,
  MarketingCheckbox,
} from 'LoggedOut/SignUp/Checkboxes';
import { AuthStatus as Status, LoginAction } from '../../App/Auth';
import { identifyBrazeUserAndMergeProfiles } from '../../App/braze/identifyBrazeUser';
import RoutePath from '../../App/RoutePath';
import Route404 from '../../App/Router/Route404';
import { tracking } from '../../App/Tracking';
import useFeatureFlag, { FEATURE_FLAGS } from '../../flags/useFeatureFlag';
import { VIRGIN_PULSE_PARTNER_NAME } from '../../LoggedOut/VirginPulse/consts';
import { useIsVirginPulseGroup } from '../../LoggedOut/VirginPulse/useIsVirginPulseGroup';
import {
  ChildProps as SubdomainFromUrlProps,
  withSubdomainFromUrl,
} from '../../LoggedOut/withSubdomainFromUrl';
import { InputSize } from '../../Shared/Form';
import {
  FormikInput,
  validateFieldNotEmpty,
  validatePasswordMatch,
} from '../../Shared/Form/Formik';
import Label from '../../Shared/Form/Label';
import { Grid, GridItem } from '../../Shared/Grid';
import LoadingIndicator, {
  withLoadingIndicator,
} from '../../Shared/LoadingIndicator';
import { PageTitle } from '../../Shared/PageTitle';
import { PrimaryButton } from '../../Shared/PrimaryButton/PrimaryButton';
import { ButtonSize } from '../../Shared/Button';
import Tooltip, { useTooltipState } from '../../Shared/Tooltip';
import FormError from '../../Shared/Typography/FormError';
import {
  getValidationResult,
  isPasswordValid,
  PasswordValidator,
} from '../../Shared/ValidatorMatch/PasswordValidator';
import { small, medium } from '../../utils';
import { useShowPrivacyConsentStepOnSignup } from '../../LoggedOut/SignUp/hooks/useShowPrivacyConsentStepOnSignup';
import { BodyText } from '../../Shared/Typography';
import { InvalidPlusOneInviteError } from './InvalidPlusOneInviteError';
import { tooltipPosition } from './PlusOneStatus';
import withCreatePlusOneUser, {
  CreatePlusOneUserProps,
  CreatePlusOneUserSuccess,
} from './withCreatePlusOneUser';
import {
  withValidPlusOneInvite,
  WithValidPlusOneInviteProps,
} from './withValidPlusOneInvite';

export interface PlusOneSignUpScreenProps
  extends SubdomainFromUrlProps,
    RouteComponentProps,
    WithValidPlusOneInviteProps,
    Pick<CreatePlusOneUserProps, 'createPlusOneUser'>,
    Pick<WithTranslation, 't'> {
  client: ApolloClient<any>;
  plusOneInviteToken: string;
  inviteeEmail?: string;
  theme: ThemeInterface;
}

const DEFAULT_ERROR_MESSAGE =
  'Sorry, there’s been an error. Please try again later.';

const Container = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 100%;
`;

const FormPanel = styled(Grid)`
  position: relative;
  z-index: 2;
  padding: 0;
`;

const LearnMoreBackgroundLight = styled(WaveLight).attrs(({ theme }) => ({
  primaryColor: tint(0.9, theme.colors.primary),
  role: 'presentation',
  width: 370,
  height: 820,
}))`
  display: none;
  position: absolute;
  right: -25%;
  bottom: -${rem(90)};
  overflow: visible;

  ${small(css`
    display: block;
  `)}

  ${medium(css`
    right: 0;
  `)}
`;

const LearnMoreBackgroundDark = styled(WaveDark).attrs(({ theme }) => ({
  primaryColor: tint(0.75, theme.colors.primary),
  role: 'presentation',
  width: 317,
  height: 732,
}))`
  display: none;
  position: absolute;
  right: 0;
  bottom: -${rem(60)};
  overflow: visible;

  ${medium(css`
    display: block;
  `)}
`;

const FieldLabel = styled(Label)`
  display: block;
  margin-bottom: ${rem(8)};
`;

const FormField = styled.div`
  margin-bottom: ${rem(24)};
  width: 100%;
`;

const FormSubmitButton = styled(PrimaryButton)`
  margin-bottom: ${rem(32)};
  margin-top: ${rem(32)};
  width: 100%;

  ${small(css`
    width: auto;
  `)}
`;

const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(8)};
  margin-top: ${rem(16)};

  label {
    margin-bottom: 0;
  }

  ${small(css`
    margin-top: 0;
  `)}
`;

const InfoIcon = styled(Info).attrs(({ theme }) => ({
  primaryColor: theme.colors.primary,
}))`
  display: flex;
  width: ${rem(22)};
  height: ${rem(22)};
  margin-left: ${rem(5)};
`;

const CenteredScreenError = styled(FormError)`
  margin-bottom: ${rem(20)};
  text-align: center;
`;

const PrivacyPointList = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  forwardedAs: 'ul',
}))`
  padding-left: ${rem(12)};
  margin-bottom: ${rem(16)};
  margin-top: ${rem(16)};
`;

const PrivacyPoint = styled.li`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${rem(16)};
  line-height: ${rem(24)};
`;

const trackTooltip = debounce((infoFor: string, subdomain: string) => {
  tracking.track('signup-plus-one-tooltip-clicked', {
    infoFor,
    subdomain,
  });
}, 1000);

export const EMAIL_TOOLTIP_CONTENT =
  'This is the email your friend or family member used to invite you to Unmind. If you’d like to use a different email, ask them to invite you again with that address.';

export interface FormFields {
  emailAddress: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  hasAgreedToTerms: boolean;
  explicitPrivacyConsentGiven: boolean;
  explicitEEADataStorageConsentGiven: boolean;
  marketingOptIn: boolean;
}

const initialValues: FormFields = {
  emailAddress: '',
  firstName: '',
  lastName: '',
  password: '',
  confirmPassword: '',
  hasAgreedToTerms: false,
  explicitPrivacyConsentGiven: false,
  explicitEEADataStorageConsentGiven: false,
  marketingOptIn: false,
};

const PlusOneSignUp = (props: PlusOneSignUpScreenProps) => {
  const [createUserError, setCreateUserError] = useState<string | undefined>(
    undefined,
  );
  const { open: tooltipOpen, handlers: tooltipHandlers } = useTooltipState();
  const plusOneEnabled = useFeatureFlag('plus-one');
  const isNewInviteFlowEnabled = useFeatureFlag(
    'team-growth-new-send-invite-flow',
  );
  const isChatBotEnabled = useFeatureFlag(FEATURE_FLAGS.ENABLE_CHATBOT);

  const {
    createPlusOneUser,
    history,
    subdomain,
    inviteeEmail,
    plusOneInviteToken,
    t: translate,
    theme,
  } = props;

  const {
    showPrivacyConsentStep: showExplicitPrivacyCheckbox,
    showEEAExplicitDataStorageConsent: showEEAExplicitDataStorageConsent,
    loading: loadingShowExplicitPrivacyCheckbox,
  } = useShowPrivacyConsentStepOnSignup(subdomain);

  const { loading: loadingIsVirginPulseGroup, isVirginPulseGroup } =
    useIsVirginPulseGroup();

  useEffect(() => {
    if (isNewInviteFlowEnabled) {
      const queryParams = new URLSearchParams();
      const token =
        (queryString.parse(props.location.search).token as string) || '';

      queryParams.append('invite_token', token);

      history.push({
        pathname: '/signup', // the new invite flow
        search: `?${queryParams.toString()}`, // appending the query parameters to url
      });
    }
  }, [isNewInviteFlowEnabled, history, props.location.search]);

  if (loadingIsVirginPulseGroup || loadingShowExplicitPrivacyCheckbox) {
    return <LoadingIndicator />;
  }

  const identifyPlusOneUser = (response: CreatePlusOneUserSuccess) => {
    const { userType, praiseDirectory, permissions, isLineManager } = response;

    const traits = {
      activationDate: response.activationDate,
      createdAt: response.createdAt,
      uuid: response.id,
      groupId: response.groupId,
      client: response.groupName,
      clientTier: response.clientTier,
      subdomain: response.subdomain,
      praiseDirectoryEnabled: Boolean(Number(praiseDirectory.value)),
      firstName: response.firstName,
      lastName: response.lastName,
      email: response.email,
      department: response.departmentName,
      departmentId: response.departmentId,
      location: response.locationName,
      locationId: response.locationId,
      userType: userType && userType.name ? userType.name : undefined,
      accessType:
        permissions && permissions.value
          ? permissions.value.toLowerCase()
          : undefined,
      timezone: response.timezone || moment.tz.guess(),
      partner: isVirginPulseGroup ? VIRGIN_PULSE_PARTNER_NAME : null,
      identifiedAsLineManager: isLineManager,
    };

    const userIdForTracking = response.id;

    tracking.identifyUser({
      userId: userIdForTracking,
      traits,
    });
  };

  const onSubmit = async (
    {
      firstName,
      lastName,
      password,
      explicitPrivacyConsentGiven,
      explicitEEADataStorageConsentGiven,
      marketingOptIn,
    }: FormFields,
    { setSubmitting }: FormikActions<FormFields>,
  ) => {
    tracking.track('signup-plus-one-clicked', { subdomain });

    const createPlusOneUserVariables = {
      email: inviteeEmail!,
      inviteToken: plusOneInviteToken,
      subdomain,
      firstName,
      lastName,
      password,
      timezone: moment.tz.guess(),
      explicitPrivacyConsentGiven,
      explicitEEADataStorageConsentGiven,
      marketingOptIn,
    };

    const response = await createPlusOneUser({
      input: createPlusOneUserVariables,
    });

    switch (response.status) {
      case Status.ERROR: {
        setCreateUserError(DEFAULT_ERROR_MESSAGE);
        setSubmitting(false);
        tracking.track('signup-plus-one-failed', { subdomain });
        break;
      }
      case Status.SUCCESS: {
        const { iris_token: irisToken, token: accessToken } = response;

        localStorage.setItem('token', accessToken);
        localStorage.setItem('irisToken', irisToken);

        await identifyBrazeUserAndMergeProfiles(props.client, response.id);
        identifyPlusOneUser(response);

        tracking.track('signup-plus-one-successful', { subdomain });
        tracking.track('login-successful', {
          subdomain,
          isFirstLogin: true,
          loginAction: LoginAction.PLUS_ONE_SIGNUP,
          type: 'success',
        });

        history.push(
          isChatBotEnabled ? RoutePath.ChatbotLanding : RoutePath.Home,
        );
        break;
      }
      default: {
        setSubmitting(false);
      }
    }
  };

  if (!plusOneEnabled) {
    return <Route404 {...props} />;
  }

  return (
    <>
      {Boolean(inviteeEmail) ? (
        <Fragment>
          <Container>
            <FormPanel>
              <GridItem sm={4} md={8} lg={8}>
                <PageTitle
                  breadcrumb={translate(
                    'plus_one_sign_up.subdomain_breadcrumb',
                    { subdomain },
                  )}
                >
                  {translate('plus_one_sign_up.title')}
                </PageTitle>
                {Boolean(createUserError) && (
                  <CenteredScreenError>{createUserError}</CenteredScreenError>
                )}
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validateOnBlur
                >
                  {({
                    dirty,
                    isSubmitting,
                    isValid,
                    values,
                    touched,
                    errors,
                    handleChange,
                    setFieldValue,
                    setFieldTouched,
                  }) => (
                    <Form>
                      <Grid>
                        <GridItem fullWidth>
                          <FormField>
                            <TooltipContainer>
                              <FieldLabel htmlFor="emailAddress">
                                {translate(
                                  'plus_one_sign_up.sign_up_form.email_field.label',
                                )}
                              </FieldLabel>
                              <Tooltip
                                {...tooltipHandlers}
                                labelCopy={translate(
                                  'plus_one_sign_up.sign_up_form.email_field.tooltip_text',
                                )}
                                placement={tooltipPosition()}
                                open={tooltipOpen}
                                accessibilityId="plusone-signup-tooltip"
                                onMouseEnter={() => {
                                  trackTooltip('email', subdomain);
                                  tooltipHandlers.onMouseEnter();
                                }}
                              >
                                <InfoIcon />
                              </Tooltip>
                            </TooltipContainer>
                            <FormikInput
                              id="emailAddress"
                              name="emailAddress"
                              inputSize={InputSize.regular}
                              value={inviteeEmail}
                              disabled
                              validate={() => !Boolean(inviteeEmail)}
                            />
                          </FormField>
                        </GridItem>
                        <GridItem sm={4} md={4} lg={6}>
                          <FormField>
                            <FieldLabel htmlFor="firstName">
                              {translate(
                                'plus_one_sign_up.sign_up_form.first_name_field.label',
                              )}
                            </FieldLabel>
                            <FormikInput
                              id="firstName"
                              name="firstName"
                              inputSize={InputSize.regular}
                              value={values.firstName}
                              validate={e =>
                                validateFieldNotEmpty(
                                  e,
                                  translate(
                                    'plus_one_sign_up.sign_up_form.first_name_field.validation_empty',
                                  ),
                                )
                              }
                            />
                          </FormField>
                        </GridItem>
                        <GridItem sm={4} md={4} lg={6}>
                          <FormField>
                            <FieldLabel htmlFor="lastName">
                              {translate(
                                'plus_one_sign_up.sign_up_form.last_name_field.label',
                              )}
                            </FieldLabel>
                            <FormikInput
                              id="lastName"
                              name="lastName"
                              inputSize={InputSize.regular}
                              value={values.lastName}
                              validate={e =>
                                validateFieldNotEmpty(
                                  e,
                                  translate(
                                    'plus_one_sign_up.sign_up_form.last_name_field.validation_empty',
                                  ),
                                )
                              }
                            />
                          </FormField>
                        </GridItem>
                        <GridItem fullWidth>
                          <FormField>
                            <FieldLabel htmlFor="password">
                              {translate(
                                'plus_one_sign_up.sign_up_form.password_field.label',
                              )}
                            </FieldLabel>
                            <FormikInput
                              id="password"
                              name="password"
                              inputSize={InputSize.regular}
                              value={values.password}
                              error={
                                Boolean(touched.password)
                                  ? errors.password
                                  : undefined
                              }
                              validate={e => !isPasswordValid(e)}
                              onChange={e => {
                                setFieldTouched('password');
                                handleChange(e);
                              }}
                              secure={true}
                            />
                            {Boolean(touched.password) && (
                              <PasswordValidator
                                {...getValidationResult(values.password)}
                              />
                            )}
                          </FormField>
                          <FormField>
                            <FieldLabel htmlFor="confirmPassword">
                              {translate(
                                'plus_one_sign_up.sign_up_form.confirm_paswword_field.label',
                              )}
                            </FieldLabel>
                            <FormikInput
                              id="confirmPassword"
                              name="confirmPassword"
                              inputSize={InputSize.regular}
                              value={values.confirmPassword}
                              validate={() =>
                                validatePasswordMatch(
                                  values.password,
                                  values.confirmPassword,
                                )
                              }
                              secure={true}
                            />
                          </FormField>
                        </GridItem>
                        {showExplicitPrivacyCheckbox ? (
                          <GridItem>
                            <BodyText
                              sizes={[
                                theme.typography.fontSizes.fontSize18,
                                theme.typography.fontSizes.fontSize20,
                                theme.typography.fontSizes.fontSize22,
                                theme.typography.fontSizes.fontSize24,
                              ]}
                            >
                              {translate(
                                'logged_out:sign_up.forms.privacy_consent.heading',
                              )}
                            </BodyText>
                            <PrivacyPointList>
                              <PrivacyPoint>
                                {translate(
                                  'logged_out:sign_up.forms.privacy_consent.point_1',
                                )}
                              </PrivacyPoint>
                              <PrivacyPoint>
                                {translate(
                                  'logged_out:sign_up.forms.privacy_consent.point_2',
                                )}
                              </PrivacyPoint>
                            </PrivacyPointList>
                            <FormField>
                              <Field
                                name="explicitPrivacyConsentGiven"
                                validate={() =>
                                  !Boolean(values.explicitPrivacyConsentGiven)
                                }
                              >
                                {({ field }: FieldProps) => (
                                  <ExplicitConsentCheckbox
                                    {...field}
                                    id="sign-up-explicit-consent"
                                    setFieldValue={setFieldValue}
                                    checked={field.value as boolean}
                                  />
                                )}
                              </Field>
                            </FormField>
                          </GridItem>
                        ) : null}
                        <GridItem>
                          <FormField>
                            <Field
                              name="hasAgreedToTerms"
                              validate={() => !Boolean(values.hasAgreedToTerms)}
                            >
                              {({ field }: FieldProps) => (
                                <TermsCheckbox
                                  {...field}
                                  id="sign-up-terms"
                                  setFieldValue={setFieldValue}
                                  checked={field.value as boolean}
                                  subdomain={props.subdomain}
                                  termsTrackingEvent="signup-plus-one-terms-conditions-clicked"
                                  partner={
                                    isVirginPulseGroup
                                      ? VIRGIN_PULSE_PARTNER_NAME
                                      : null
                                  }
                                />
                              )}
                            </Field>
                          </FormField>
                          {showEEAExplicitDataStorageConsent ? (
                            <FormField>
                              <Field
                                name="explicitEEADataStorageConsentGiven"
                                validate={() =>
                                  !Boolean(
                                    values.explicitEEADataStorageConsentGiven,
                                  )
                                }
                              >
                                {({ field }: FieldProps) => (
                                  <StoringDataInEEACheckbox
                                    {...field}
                                    id="storing-data-eea"
                                    setFieldValue={setFieldValue}
                                    checked={field.value as boolean}
                                  />
                                )}
                              </Field>
                            </FormField>
                          ) : null}
                          <FormField>
                            <Field name="marketingOptIn">
                              {({ field }: FieldProps) => (
                                <MarketingCheckbox
                                  {...field}
                                  aria-label="marketingOptIn"
                                  setFieldValue={setFieldValue}
                                  checked={field.value as boolean}
                                />
                              )}
                            </Field>
                          </FormField>
                        </GridItem>
                        <GridItem>
                          <FormSubmitButton
                            disabled={!dirty || !isValid}
                            label={translate(
                              'plus_one_sign_up.sign_up_form.submit_form_button',
                            )}
                            loading={isSubmitting}
                            size={ButtonSize.medium}
                            type="submit"
                          />
                        </GridItem>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </GridItem>
            </FormPanel>

            <LearnMoreBackgroundLight />
            <LearnMoreBackgroundDark />
          </Container>
        </Fragment>
      ) : (
        <InvalidPlusOneInviteError />
      )}
    </>
  );
};

export default withTranslation('plus_one')(
  compose<PlusOneSignUpScreenProps, PlusOneSignUpScreenProps>(
    withApollo,
    withProps((props: RouteComponentProps) => ({
      ...props,
      plusOneInviteToken: queryString.parse(props.location.search).token || '',
    })),
    withSubdomainFromUrl,
    withValidPlusOneInvite,
    withLoadingIndicator,
    withCreatePlusOneUser,
    withTheme,
  )(PlusOneSignUp),
);
